
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#map {
  height: 100%;
  width: 100%;
}

#logo {
  position: absolute;
  top: 10px;
  right: 30px;
  /* z-index: 1; */
}
#logo img{
  height: 35px;
  width: auto;
}

/*FORM CONTROL*/
table {
  border-collapse: collapse;
  width: 100%;
  height: 100%;
}

th, td {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  padding: 5px;
}
td {
  width: 250px;
}
th{
  text-align: right;
}


h4{
  padding-left: 20px;
  font-size: 19px;
  font-weight: normal;
  line-height: 20px;
  margin-bottom: 10px;
  margin-top: auto;
}

h4 img{
  margin-right: 10px;
  height: 20px;
}

.geocode-row th,
.geocode-row td {
  border: none;
  border-bottom: 20px;
  
}

a.morebutton {
  display: inline-block;
  background-color:#40C706;
  color: white;
  padding: 8px 12px;
  border-radius: 4px;
  margin-left: auto;
  margin-right: auto;
  font-size: 12px;
  text-decoration: none;
  margin-top: 10px;
  word-wrap: break-word;
  /* width: 100%; */
}

/* a.morebutton:hover {
  background-color: #40C706;
} */

#logo, .layercontrol, .mycontainer, .search-result {
  display: block;
}

.customizedinput{
  display: flex;
  top: 20px;
  left: 25px;
  align-items: center;
  position: absolute;
  width: 100px;
  height: 30px;
  min-width: 316px;
  border-radius: 3px;
  border: 1px solid #B8B8B8;


}

.mycontainer{
  background-color: #ffffff;
  width: 100px;
  margin-left: -1px;
  /* top: 52px;
  left: 25px; */
  /* position: absolute; */
  padding: 8px;
  padding-bottom: 20px;
  min-width: 300px;
  border: 1px solid #B8B8B8;
  border-radius: 3px;
}


.search-result{
  background-color: #ffffff;
  width: 100px;
  /* top: 52px;
  left: 25px; */
  /* position: absolute; */
  padding: 8px;
  padding-bottom: 20px;
  min-width: 300px;
  border: 1px solid #B8B8B8;
  border-radius: 3px;
}

.layercontainer{
  max-width: calc(100% - 50px);
  position: absolute;
  top: 50px;
  right: 20px;
  display: block;
  
  /* border: 10px solid #ff0000; */
}

.layercontrol {
  /* margin-top: -15px; */
  position: relative;
}

/* .searchresult-box{
  width: 27%;
  background-color: #ffffff;
  height: 36px;
  margin-top: 2px;
  border: 1px solid #B8B8B8;
  border-radius: 3px;
  min-width: 300px;
} */
@media (max-width: 480px) {
  /* #logo, */
  .layercontrol {
    /* display: flex;
    margin-top: 40px; */
    /* position: absolute !important;
    bottom: 30px !important;
    border: 10px solid #ff0000 !important; */
  }

  .layercontainer{
    /* width: 100%; */
    /* height: 100px; */
    max-width: calc(100% - 50px);
    /* position: absolute; */
    bottom: 35px;
    left: 10px;
    top: unset;
    right: unset;
    display: none;
    /* border: 1px solid #ff0000; */
  }
  
  .mycontainer,
  .search-result {
    /* display: none !important; */
  }



  .customizedinput {
    min-width: 216px;
    width: calc(100% - 90px);
  }
  .mycontainer {
    min-width: 200px;
    margin-left: 8px;
    width: calc(100vw - 70px);
  }

  #logo{
    overflow: hidden; 
    top: 20px;
    right: 25px;
  }
  #logo img{
    /* border: 1px solid #ff0000; */
    height: 30px;
    width: auto;
    /* margin-left: -10px; */
    margin: 0px 0px 0px -68px; 
  }
}







